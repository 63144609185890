@import "colors";

html, body {
  background-color: $app-background;
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.no-padding {
  padding: 0 !important;
}

section.main-content {
  padding: 20px 25px 25px 25px;
}

h1 {
  color: dimgrey;
  font-size: 25px;
  cursor: default;
}

ul.no-bullets {
  list-style-type: none;
  padding: 2px 0 2px 0;
  margin: 0;
  max-height: 60px;
  overflow: auto;
}

.grey-label {
  color: rgba(0, 0, 0, .54);
  font-size: 13px;
  font-weight: 500;
}

.blue-label {
  color: $app-primary;
  font-weight: 400;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 20px;
}

table mat-cell button.table-button, table td button.table-button, table mat-cell a.table-button, table td a.table-button, {
  min-width: 6px;
  padding: 0 0;
  color: #757575
}

.green {
  color: $app-success;
}

.red {
  color: $app-error;
}

.blue {
  color: $app-primary;
}

.grey {
  color: $app-grey;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $app-primary
}

.pointer {
  cursor: pointer;
}

.btn-background-blue {
  background-color: $app-primary;
  padding: 0 10px;
  color: white;
  border-radius: 3px;
  font-weight: 400;
}

.btn-background-blue:disabled {
  background-color: rgba(0, 0, 0, .12);
}

.fw-500 {
  font-weight: 500;
}

.fw-900 {
  font-size: 15px;
  font-weight: 900;
}

.label-success {
  border-radius: 7px;
  color: white;
  padding: 4px;
  background-color: $app-success;
}

.label-warning {
  border-radius: 7px;
  color: white;
  padding: 4px;
  background-color: $app-warning;
}

.label-error {
  border-radius: 7px;
  color: white;
  padding: 4px;
  background-color: $app-error;
}

.label-default {
  border-radius: 7px;
  color: white;
  padding: 4px;
  background-color: $app-primary;
}

.alert-error {
  padding: 20px;
  background-color: #f44336;
  color: white;
}
.alert-error button {
  background-color: #f44336;
  color: white !important;
  border: none;
}
